import './App.css';
import React from 'react';
import './Perrongen.css';
import logo from './Perrongen_logo.svg';
import headerPicture from './img/drink-header.png';
import {gql, useQuery} from '@apollo/client';

const query = gql`
query {
  headerImageCollection {
    items {
      page
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
    }
  }
}
`;

function FirstHeader(){
  const {loading, data} = useQuery(query)
  if (loading) return <h2> </h2>
  console.log(data);


  const headerImage = data.headerImageCollection.items.find(({page}) => page === 'firstHeader').image.url;

  return (
    <div className="Header">
      <img className="header-picture" src={headerImage} alt="perrongen" />
      <div className="logo-container">
        {/* <PerrongenLogo /> */}
        <img className="header-logo" src={logo} alt="Logo" />
      </div>
    </div>
  ); 
};

export default FirstHeader