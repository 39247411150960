import './Perrongen.css';
import {gql, useQuery} from '@apollo/client';
import ImagesContainer from './ImagesContainer';

const query = gql`
query {
  storeSupplierCollection {
    items {
      title
      link
      logo {
        url
      }
      description {
        json
      }
    }
  }
  openingHoursStoreCollection {
    items {
      days
      hours
    }
  }
}
`;

function StorePage(props) {

  const {loading, data} = useQuery(query)
  if (loading) return <h2> </h2>


  const storeSuppliers = data.storeSupplierCollection.items;
  const storeOpeningHours = data.openingHoursStoreCollection.items[0].hours;
  const storeOpeningDays = data.openingHoursStoreCollection.items[0].days

  return (
    <div>
      <div className="rowContainer">
        <div>
          <div className='page-text-container'>{props.text}</div>
        </div>
      </div>
      <div className="rowContainer">
        <div className="rowItem">
          <h2>Varumärken i butiken</h2>
          <div className="store-suppliers">
            {storeSuppliers.map((store, index) => (
              <div key={index} className="store-supplier">
                <div className="supplier-logo-container">
                  <a href={store.link} target="_blank" ><img className="supplier-logo" src={store.logo.url} alt="Perrongen_poster" /></a>
                </div>
                <div className="supplier-text-container">
                  <p>{store.description.json.content[0].content[0].value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="rowItem">
          <h2>Öppettider</h2>
          <p>{storeOpeningDays}</p>
          <p>{storeOpeningHours}</p>
        </div> */}
      </div>
      <div className="rowContainer">
        <ImagesContainer category="storePicture"></ImagesContainer>
      </div>
    </div>
    
  );
}

export default StorePage;

