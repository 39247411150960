import './Perrongen.css';
import {gql, useQuery} from '@apollo/client';
import { useEffect, useState } from 'react';
import Lightbox from 'react-images';

const query = gql`
query {
  activityPosterCollection {
    items {
      title
      picture {
        title
        url
        width
        height
      }
    }
  }
  cafePictureCollection {
    items {
      picture {
        url
      }
    }
  }
  restaurantPictureCollection {
    items {
      picture {
        title
        url
        width
        height
      }
    }
  }
  storePictureCollection {
    items {
      picture {
        title
        url
        width
        height
      }
    }
  }
}
`;

function ImagesContainer(props) {
  const {loading, data} = useQuery(query);
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  if (loading) return <h2> </h2>
 
  let images = [];

  if (props.category === "activityPoster") {
    images = data.activityPosterCollection.items;
  } else if (props.category === "cafePicture") {
    images = data.cafePictureCollection.items;
  } else if (props.category === "restaurantPicture") {
    images = data.restaurantPictureCollection.items;
  } else if (props.category === "storePicture") {
    images = data.storePictureCollection.items;
  } 

  const photoList = [];

  images.map(image => {
    photoList.push({src: image.picture.url, width: image.picture.width, height: image.picture.height, title: image.picture.title })
  });
  
  return (
    <div className="rowContainer images-container pill ${ this.props.styleName }" onClick={() => setLightboxIsOpen(true)}>
      {photoList.map((photo, index) => (
        <div key={index} className="image-container">
          <img src={photo.src} alt="Perrongen_poster" />
        </div>
      ))}
      <Lightbox images={photoList}
              onClose={() => {setLightboxIsOpen(false); setCurrentImage(0)}}
              onClickPrev={() => setCurrentImage(currentImage-1)}
              onClickNext={() => setCurrentImage(currentImage+1)}
              currentImage={currentImage}
              isOpen={lightboxIsOpen}
              backdropClosesModal={true}
            />
    </div>);
}

export default ImagesContainer;

