import './Perrongen.css';
import {gql, useQuery} from '@apollo/client';
import ImagesContainer from './ImagesContainer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { printIntrospectionSchema } from 'graphql';

const query = gql`
query {
  cafeMenuPdfCollection {
    items {
      file {
        title
        url
      }
    } 
  }
  openingHoursCafeCollection {
    items {
      days
      hours
    } 
  }
  longTextCollection {
    items {
      title
      text {
        json
      }
    }
  }
}
`;

function CafePage(props) {

  const {loading, data} = useQuery(query)
  if (loading) return <h2>...</h2>

  const cafePageText = data.longTextCollection.items.find( ({ title }) => title === 'cafe page text' )
  const textComponent = documentToReactComponents(cafePageText.text.json);

  const cafeOpeningHours = data.openingHoursCafeCollection.items[0].hours;
  const cafeOpeningDays = data.openingHoursCafeCollection.items[0].days;

  return (
    <div>
      <div className="rowContainer">
        <div>
          <div className='page-text-container'>{props.text}</div>
        </div>
      </div>
      <div className="rowContainer">
        <div className="rowItem">
          <h2>Öppettider</h2>
          <p>{cafeOpeningDays}</p>
          <p>{cafeOpeningHours}</p>
        </div>
      </div>
      <div className="rowContainer">
        <ImagesContainer category="cafePicture"/>
      </div>
    </div>
    
  );
}

export default CafePage;

