import './App.css';
import React from 'react';
import './Perrongen.css';
import { Link } from "react-router-dom";
import logo from './img/Perrongen_logo_BT.png';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const SecondHeader = (props) => {

    const textComponent = documentToReactComponents(props.data.pageText.json);
    return (
      <div className="Header">
        <img className="header-picture" src={props.data.headerImage.url} alt="perrongen" />
        <div>
          <Link to="/">
            <div className="second-logo-container">
              <img className="second-header-logo" src={logo} alt="Logo" />
            </div>
          </Link>
        </div>  
        <div className="second-title-container">
            <h1>{props.data.title}</h1>
        </div>
      </div>
    ); 
};

export default SecondHeader;