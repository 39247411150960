import './Perrongen.css';
import {gql, useQuery} from '@apollo/client';
import ImagesContainer from './ImagesContainer';

const query = gql`
query {
  dinnerMenuPdfCollection {
    items {
      file {
        title
        url
      }
    } 
  }
  lunchMenuPdfCollection {
    items {
      file {
        title
        url
      }
    }
  }
  openingHoursLunchCollection {
    items {
      days
      hours
    } 
  }
  openingHoursDinnerCollection {
    items {
      days
      hours
    } 
  }
}
`;

function RestaurantPage(props) {

  const {loading, data} = useQuery(query)
  if (loading) return <h2> </h2>


  const lunchPDFurl = data.lunchMenuPdfCollection.items[0].file.url;
  const dinnerPDFurl = data.dinnerMenuPdfCollection.items[0].file.url;
  const dinnerOpeningHours = data.openingHoursDinnerCollection.items[0].hours;
  const dinnerOpeningDays = data.openingHoursDinnerCollection.items[0].days;
  const lunchOpeningHours = data.openingHoursLunchCollection.items[0].hours;
  const lunchOpeningDays = data.openingHoursLunchCollection.items[0].days;

  return (
    <div>
      <div className="rowContainer">
        <div>
          <div className='page-text-container'>{props.text}</div>
        </div>
      </div>
      <div className="rowContainer restaurant-info">
        <div className="rowItem">
          <h2>Lunch</h2>
          <p>{lunchOpeningDays}</p>
          <p>{lunchOpeningHours}</p>
          <a href={lunchPDFurl} target="_blank">
            <div className="small-button">Meny</div>
          </a>
        </div>
        <div className="rowItem">
          <h2>Middag</h2>
          <p>{dinnerOpeningDays}</p>
          <p>{dinnerOpeningHours}</p>
          <a href={dinnerPDFurl} target="_blank">
            <div className="small-button">Meny</div>
          </a>
        </div>
      </div>
      
      <div className="rowContainer">
        <ImagesContainer category="restaurantPicture"/>
      </div>
    </div>
    
  );
}

export default RestaurantPage;

