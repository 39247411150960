import './App.css';
import React from 'react';
import './Perrongen.css';
import {
  Switch,
  Route,
  
} from "react-router-dom";

import FirstHeader from './FirstHeader';
import SecondHeader from './SecondHeader';

function Header(props) {

  const cafeData = props.data.find( ({ id }) => id === 'cafe' );
  const restaurantData = props.data.find(({ id }) => id === 'restaurang');
  const storeData = props.data.find(({ id }) => id === 'butik');

  return <div>
    <Switch>
      <Route exact path="/">
        <FirstHeader title="Front page"/>
      </Route>
      <Route path="/cafe">
        <SecondHeader title="Café" data={cafeData}/>
      </Route>
      <Route path="/restaurang">
        <SecondHeader title="Restaurang" data={restaurantData}/>
      </Route>
      <Route path='/butik'>
        <SecondHeader title="Butik" data={storeData}/>
      </Route>
    </Switch>
  </div>
};


export default Header;



