import './App.css';
import React, { Component } from 'react';
import './Perrongen.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './img/Perrongen_logo.png';
import FrontPage from './frontPage';
import CafePage from './cafePage';
import RestaurantPage from './restaurantPage';
import StorePage from './storePage';
import Header from './Header'
import {gql, useQuery} from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const query = gql`
query {
  pageInfoCollection {
    items {
      id
      headerImage {
        url
        width
        height
      }
      buttonImage {
        url
        width
        height
      }
      title
      pageText {
        json
      }
    }
  } 
}
`;

function App() {
  const {loading, data} = useQuery(query)
  if (loading) return <h2>...</h2>

  const pageInfoCollection = data.pageInfoCollection.items;

  return (
    <Router>
      <div className="App">
        <Header data={pageInfoCollection}/>
        <Page data={pageInfoCollection}/>
        <Footer />
      </div>
    </Router>
    );
};

export default App;


function Page(props) {
  const cafeData = props.data.find( ({ id }) => id === 'cafe' );
  const restaurantData = props.data.find(({ id }) => id === 'restaurang');
  const storeData = props.data.find(({ id }) => id === 'butik');
  const cafeTextComponent = documentToReactComponents(cafeData.pageText.json);
  const storeTextComponent = documentToReactComponents(storeData.pageText.json);
  const restaurantTextComponent = documentToReactComponents(restaurantData.pageText.json);
  
  return <div className="Page">
    <Switch>
      <Route exact path="/">
        <FrontPage />
      </Route>
      <Route path="/cafe">
        <CafePage text={cafeTextComponent} />
      </Route>
      <Route path="/restaurang">
        <RestaurantPage text={restaurantTextComponent}/>
      </Route>
      <Route path='/butik'>
        <StorePage text={storeTextComponent}/> 
      </Route>
    </Switch>
  </div>
};

function Footer() {
  return <div className="Footer">
    <div className="rowContainer">
      <div className="rowItem">
        <img className="footer-logo" src={logo} alt="Logo" />
      </div>
    </div>
    <div className="rowContainer footer-info">
      <div className="footer-contact-info">
        <p>info@restaurangperrongen.se</p>
        <p>0433 - 100 80</p>
      </div>
      <div className="footer-address">
        <p>Markaryds Stationshus AB</p>
        <p>Drottninggatan 1D</p>
        <p>285 31  Markaryd</p>
      </div>
    </div>
 </div>
};