import './Perrongen.css';
import './layout.css';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import {gql, useQuery} from '@apollo/client';
import ImagesContainer from './ImagesContainer';
import Iframe from 'react-iframe';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const query = gql`
query {
  openingHoursCafeCollection {
    items {
      days
      hours
    } 
  }
  openingHoursLunchCollection {
    items {
      days
      hours
    } 
  }
  openingHoursDinnerCollection {
    items {
      days
      hours
    } 
  }
  openingHoursStoreCollection {
    items {
      days
      hours
    } 
  }
  pageInfoCollection { 
    items {
      id
      buttonImage {
        url
      }
    }
  }
  longTextCollection {
    items {
      title
      text {
        json
      }
    }
  }
}
`;

function FrontPage() {
  const {loading, data} = useQuery(query)
  if (loading) return <h2>...</h2>
  console.log(data);

  const frontPageText = data.longTextCollection.items.find( ({ title }) => title === 'Front page text' )
  const textComponent = documentToReactComponents(frontPageText.text.json);
  const cafeOpeningHours = data.openingHoursCafeCollection.items[0].hours;
  const cafeOpeningDays = data.openingHoursCafeCollection.items[0].days;
  const dinnerOpeningHours = data.openingHoursDinnerCollection.items[0].hours;
  const dinnerOpeningDays = data.openingHoursDinnerCollection.items[0].days;
  const storeOpeningHours = data.openingHoursStoreCollection.items[0].hours;
  const storeOpeningDays = data.openingHoursStoreCollection.items[0].days;
  const lunchOpeningHours = data.openingHoursLunchCollection.items[0].hours;
  const lunchOpeningDays = data.openingHoursLunchCollection.items[0].days;
  const cafePicture = data.pageInfoCollection.items.find( ({ id }) => id === 'cafe' ).buttonImage.url;
  const restaurantPicture = data.pageInfoCollection.items.find( ({ id }) => id === 'restaurang' ).buttonImage.url;
  const storePicture = data.pageInfoCollection.items.find( ({ id }) => id === 'butik' ).buttonImage.url;
  
  
  return (

    <div className="FrontPage">
      <div classname="rowcontainer">
        <div className='front-page-text-container'>{textComponent}</div>
      </div>
      <div className="rowContainer navContainer">
        <Link to="/cafe" className="navItem">
          <div className="nav-button">
            <img src={cafePicture} className="nav-image" alt="Perrongen_cafe" />            
            <div className="nav-text centered"><p>Café</p></div>
          </div> 
        </Link>
        <Link to="/restaurang" className="navItem">
          <div className="nav-button">
            <img src={restaurantPicture} className="nav-image" alt="Perrongen_cafe" />            
            <div className="nav-text centered"><p>Restaurang</p></div>
          </div> 
        </Link> 
        <Link to="/butik" className="navItem">
          <div className="nav-button">
            <img src={storePicture} className="nav-image" alt="Perrongen_cafe" />            
            <div className="nav-text centered"><p>Butik</p></div>
          </div> 
        </Link>
      </div>
      
      <div className="rowContainer front-page-row">
        <div className="rowContainer front-opening-hours">
          <h1>Öppettider</h1>
          <div className="rowContainer openingHoursContainer">
            <div className="rowItem">
              <h3>Lunch</h3>
              <p>{lunchOpeningDays}</p>
              <p>{lunchOpeningHours}</p>
            </div>
            <div className="rowItem ">
              <h3>Middag</h3>
              <p>{dinnerOpeningDays}</p>
              <p>{dinnerOpeningHours}</p>
            </div>
          </div>
          <div className="rowContainer openingHoursContainer">  
            <div className="rowItem">
              <h3>Café</h3>
              <p>{cafeOpeningDays}</p>
              <p>{cafeOpeningHours}</p>
            </div>
            <div className="rowItem">
              <h3>Butik</h3>
              <p>{storeOpeningDays}</p>
              <p>{storeOpeningHours}</p>
            </div>
          </div>
        </div> 
        <div className="booking-module">
          <h1>Boka</h1>
          <Iframe className="booking-module-iframe" src="https://module.lafourchette.com/sv_SE/module/681293-2f42f" style="width: 100%; min-height:800px; border:none; scrolling:yes;"></Iframe>
        </div>
      </div>
      
      <div className="rowContainer">
          <h2>På gång</h2>
      </div>
      <div className="rowContainer">
        <ImagesContainer category="activityPoster" styleName="activity-posters"/>
      </div>
    </div>
  );
}

export default FrontPage;
